

.pages_dialog{
    height: 80vh;
    width: calc(100% - 2rem);
    max-width: 50rem;
    
    .p-dialog-content{
        display: flex;
        .p-listbox{
            flex: 1;
            display: flex;
            flex-direction: column;
        }
    }
    
}