
.builder-block{
    width: 100%;
    padding: 2rem;
    padding-bottom: 4rem;
    //background-color: var(--surface-50);
    //border: 1px solid var(--surface-600);

    margin: 0.5rem 0;
    position: relative;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    align-items: stretch;
    
    &.initiated{
        //border: 2px solid var(--gray-400);;
    }
    &.completed{
        border: 1px solid var(--yellow-400);
    }
    &.error{
        border: 1px solid var(--red-400);
    }

    .preview-pic{
        width: 50vw;
    }
    .controls{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.4rem;
        bottom: 1rem;
        right: 1rem;

        .control{
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--gray-400);
            cursor: pointer;
            font-size: 1.8rem;
            &:hover{
                color: var(--red-400);
            }
        }
    }

    .status-icon{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 1rem;
        right: 1rem;
        gap:1rem;
        .pi{
            font-size: 2rem;
            font-weight: 700;

            &.error{
                color: var(--red-400);
            }
            &.completed{
                color: var(--yellow-400);
            }
            &.initiated{
                color: var(--gray-400);
            }
            &.pi-image{
                color: var(--gray-400);
                cursor: pointer;
            }
        }
        
    }

    .checkbox-label{
        margin-left:0.25rem;
        font-size: 1.2rem;
    }
    .p-card-subtitle{
        font-size:1.4rem;
        i{
            margin-left: 0.5rem;
        }
    }
    .collection{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        //border:1px solid red;
        gap:0.5rem;
        .item{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width:100%;
            //border:1px solid green;
        }
    }
}