.App {
  

  .sidebar{
    background-color: var(--surface-overlay);
    font-size: inherit;
    width: 360px;
  }

  .header{
    width: calc(100% - 360px);
    height: 4rem;
    z-index:2;
    background-color: var(--surface-overlay);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-shadow:rgba(0, 0, 0, 0.16) 8px 5px 13px 4px;
  }
  .content{
    width: 100%;
    max-width: calc(100% - 360px);

    margin-top: 4rem;
    height: calc(100vh - 4rem);
    overflow: scroll;

    text-align: center;
    font-size: calc(10px + 2vmin);
    color: white;
    background-color: var(--surface-ground);
  }

  .preview{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-left:1px solid var(--orange-100);
    .controls{
      background: magenta;
      width: 100%;
      height: 8rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    iframe{
      background-color: var(--surface-ground);
      transition: all 250ms ease-in-out;

      &.full-screen{
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9;
        height: calc(100% - 4rem);
        padding: 0 !important;
        
      }
    }
  }
}
