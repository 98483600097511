.whats-in-block{
    /*
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
*/

    .cards-list{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;

        .card{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap:2rem;

            .w-50{
                width: 100%;
                max-width: calc(50% - 1rem);
            }
        }
    }
}