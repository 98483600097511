

.toolbox{

    display: flex;
    flex-wrap: wrap;
    gap:2rem;

    padding: 1rem;
    width: 100%;
    .tool {
        width: calc(50% - 1rem);
        height: max-content;
        padding:0;
        position: relative;

        .draggable{
            cursor:grab;
            width: 100%;
            height: 100%;
            position: absolute;
            top:0;
            left:0;
        }
    }
}