.gallery-block{

    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;

    .pic-list{
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        align-items: flex-start;

        .pic{
            width: 10rem;
            height: 10rem;
            overflow: hidden;
            border-radius: 0.3rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: flex-start;
            position: relative;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .delete-pic{
                position: absolute;
                top:0.5rem;
                right:0.5rem;
                border-radius: 0.2rem;
                background: rgba(0,0,0,0.6);
                -webkit-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.6);
                -moz-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.6);
                box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.6);
                
                cursor: pointer;

                &:hover{
                    color:var(--orange-300);
                }
            }
        }
    }
}