.links-block{

    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;


    .links-list{
        display:flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap:1rem;
        .link{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap:0 1rem;
        }
    }
}