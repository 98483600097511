
.characteristics-block{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;

    .collection{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;

        .item{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: flex-start;
        }
    }
}