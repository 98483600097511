
.image-uploader{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin:1rem 0;
    gap:1rem;

    .pic-preview{
        height: 4rem;
        width: auto;
    }
}



