
.canvas{
    position: relative;
    .droppable-layer{
        width:100%;
        height:100%;
        position:fixed;
        background-color:black;
        opacity: 0;
        &.is-dragging{
            opacity: 0.8;
            z-index: 2;
        }
    }
    .droppable-region{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-left:auto;
        min-height: 100%;

        padding: 2rem;
        padding-bottom: 5rem;
        &.dragging-over{
            background: var(--bluegray-800);
            border:2px dashed var(--orange-500);
        }

        .empty-state{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: var(--gray-400);
            position: absolute;
            top: 0;
            padding: 5rem;
            bottom: 0;
            margin: auto;
            .icon .pi{
                font-size: 3rem;
            }
            .label{
                font-size: 1.5rem;
            }
        }
    }

    .toolbar{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: fixed;
        bottom:0;
        right:0;
        height: 4rem;
        //background: var(--surface-overlay);
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
        box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 13px 4px;
        gap:1rem;
        width: calc(100% - 360px);
        transition: all 150ms ease-in-out;
        z-index: 2;
        &.expanded{
            height:100vh;
        }
        .label{
            color: var(--text-secondary-color);
            font-size: 1rem;
        }

        .panel{
            margin:auto;
            width: calc(100% - 4rem);
            max-width: 30rem;
            .page-details{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;

                gap:1rem;
                .dropdown-placeholder, .p-dropdown .p-dropdown-label {
                    text-align: left;
                }
            }
            .footer{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 1rem;
                padding:1rem 0 0 0;
                width: 98%;
                margin: auto;
            }
        }
    }
    .results-modal{
        position: fixed;
        top:0;
        bottom:0;
        right:0;
        left: 0;
        margin:auto;
        width: max-content;
        min-width: 40vw;
        min-height: 40vh;
        height: max-content;
        background-color: var(--surface-overlay);
        box-shadow: rgba(0, 0, 0, 0.6) 0px 0 5px 9000px;
        overflow: hidden;
        border-radius: 0.5rem;
        .page-details{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            gap:1rem;
            .dropdown-placeholder, .p-dropdown .p-dropdown-label {
                text-align: left;
            }
        }
        .footer{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 1rem;
            padding:1rem 0 0 0;
            width: 98%;
            margin: auto;
        }
    }
}