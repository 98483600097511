

.login-wrapper{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    display: none;
    margin:auto;
    &.visible{
        display: flex;
    }
}