

.carousel-block{
    


    .marquees-list{

        max-width: 100%;

        
        .marquee{
            max-width: 100%;

            
            .pic-list-title{
                font-size: 1.2rem;
            }
            .pic-list{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width:100%;
                max-width: 560px;
                height: max-content;
                overflow: scroll;
                padding:0.5rem;
                gap:0.5rem;
                
                .pic{
                    display: flex;
                    position: relative;
                    align-items: center;
                    justify-content: center;
                    width: 10rem;
                    min-width: 10rem;
                    img{
                        width: 100%;
                        border-radius: 0.4rem;
                    }
                    .delete-pic{
                        position: absolute;
                        top:0.4rem;
                        right:0.4rem;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}


